import Button from 'react-bootstrap/esm/Button';
import Container from 'react-bootstrap/esm/Container';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/esm/Form';
import Axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import { getError } from '../util';
import MessageBox from '../component/MessageBox';
import LoadingBox from '../component/LoadingBox';


export default function SigninScreen() {
  const naviagte = useNavigate();
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get('redirect');
  const redirect = redirectInUrl ? redirectInUrl : '/';

  const [code, setCode] = useState('');

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo, customers, products, cli } = state;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { PDFDocument, rgb } = require('pdf-lib');


  const submitHandler = async (e) => {
    
    const message = `Hello, ${userInfo.name} here from Madni Corporation`;
    const whatsappLink = `https://wa.me/${code}?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, '_blank');    
  }
  const SharePDF = async(e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const result = (await Axios.get('/api/products/ordersheet')).data;
      //console.log(result)
      const pdfDoc = await PDFDocument.create();

      // Define some constants for the layout
      let PAGE_MARGIN = 15;
      let HEADER_ROW_Y = 800;
      let ROW_HEIGHT = 17;
      let FONT_SIZE = 10;
      
      // Add a page to the PDF document
      let page = pdfDoc.addPage();

      // Set the font and font size for the text
      const font = await pdfDoc.embedFont('Courier');
      const boldFont = await pdfDoc.embedFont('Courier-Bold');
      
      // Define the header row
      const headerRow = ['CODE', 'NAME OF PRODUCT', 'UNIT', 'RATE', 'QTY'];
      const headerRowWidths = [0, 50, 110, 110, 110];


      // Add Company Name
      const compName = ""
      const rptName = "Order Form"
      page.drawText(compName, {
        x:  PAGE_MARGIN + ((headerRowWidths.reduce((colsum, a) => colsum + a, 0)/2) - (compName.length / 2)) + 20,
        y: 800,
        size: FONT_SIZE + 10,
        font:boldFont,
      });

      // Order Sheet
      page.drawText(rptName, {
        x: PAGE_MARGIN + ((headerRowWidths.reduce((colsum, a) => colsum + a, 0)/2) - (rptName.length / 2)) + 50,
        y: HEADER_ROW_Y - ROW_HEIGHT,
        size: FONT_SIZE + 5,
        font:boldFont,
      });

      HEADER_ROW_Y = HEADER_ROW_Y - (ROW_HEIGHT*2)

      page.drawLine({
        start: { x: PAGE_MARGIN, y: HEADER_ROW_Y + 15},
        end: { x: page.getWidth() - PAGE_MARGIN, y: HEADER_ROW_Y + 15 },
        size: 5,
       // color: rgb(0, 0, 0), // Set the color to black
      });

      // // Add the header row
      // for (let i = 0; i < headerRow.length; i++) {
      // page.drawText("MADNI CORPORATION", {
      //   x: PAGE_MARGIN + (i * headerRowWidths[i]),
      //   y: HEADER_ROW_Y,
      //   size: FONT_SIZE,
      //   font:boldFont,
      // });
      // }


      // Add the header row
      for (let i = 0; i < headerRow.length; i++) {
        page.drawText(headerRow[i], {
          x: PAGE_MARGIN + (i * headerRowWidths[i]),
          y: HEADER_ROW_Y,
          size: FONT_SIZE,
          font:boldFont,
        });
      }
      page.drawLine({
        start: { x: PAGE_MARGIN, y: HEADER_ROW_Y - 5 },
        end: { x: page.getWidth() - PAGE_MARGIN, y: HEADER_ROW_Y - 5 },
       // color: rgb(0, 0, 0), // Set the color to black
      });
      // Add the data rows
      let currentPageY = HEADER_ROW_Y - ROW_HEIGHT;
      for (let i = 0; i < result.length; i++) {
        const row = result[i];

        // Check if the row fits on the current page
        if (currentPageY < PAGE_MARGIN) {
          // Add a new page if the row doesn't fit
          page = pdfDoc.addPage();
          //spage.setFillColorRgb([1, 0, 0]);
          currentPageY = HEADER_ROW_Y - ROW_HEIGHT;

          page.drawLine({
            start: { x: PAGE_MARGIN, y: HEADER_ROW_Y + 15},
            end: { x: page.getWidth() - PAGE_MARGIN, y: HEADER_ROW_Y + 15 },
            size: 5,
           // color: rgb(0, 0, 0), // Set the color to black
          });
    
          // Add the header row
          for (let i = 0; i < headerRow.length; i++) {
            page.drawText(headerRow[i], {
              x: PAGE_MARGIN + (i * headerRowWidths[i]),
              y: HEADER_ROW_Y,
              size: FONT_SIZE,
              font:boldFont,
            });
          }
          page.drawLine({
            start: { x: PAGE_MARGIN, y: HEADER_ROW_Y - 5 },
            end: { x: page.getWidth() - PAGE_MARGIN, y: HEADER_ROW_Y - 5 },
          });
         }

        
         if (row.ItemLevel !== 'E')
         {


         page.drawRectangle({
          x: PAGE_MARGIN,
          y: currentPageY - (ROW_HEIGHT-12),
          width: page.getWidth() - 2 * PAGE_MARGIN,
          height: ROW_HEIGHT,
          fillColor: { r: 0, g: 0, b: 0 }, // Black background
        });

        page.drawText(row.ItemCode, {
          x: PAGE_MARGIN,
          y: currentPageY,
          size: FONT_SIZE,
          font,
          color: rgb(1, 1, 1)
        });
      }
      else {
        page.drawText(row.ItemCode, {
          x: PAGE_MARGIN,
          y: currentPageY,
          size: FONT_SIZE,
          font,
        });
      }
        page.drawText(row.ItemDesc.toString(), {
          x: PAGE_MARGIN + headerRowWidths[1],
          y: currentPageY,
          size: FONT_SIZE,
          font,
          color : row.ItemLevel === 'E' ?  rgb(0, 0, 0) :  rgb(1, 1, 1)
        });
        page.drawText(row.ItemUnit, {
          x: PAGE_MARGIN +  (headerRowWidths[2] * 2),
          y: currentPageY,
          size: FONT_SIZE,
          font,
          color : row.ItemLevel === 'E' ?  rgb(0, 0, 0) :  rgb(1, 1, 1)

        });

        const text = row.ItemRate.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        page.drawText(text, {
          x: PAGE_MARGIN +  headerRowWidths[1] + (headerRowWidths[3]*3)-(text.length>4 ? (text.length*FONT_SIZE)-text.length-(text.length<7?2:8) : (text.length*FONT_SIZE)-1 ) ,//+(10-(text.length*(FONT_SIZE-2))),
          y: currentPageY,
          size: FONT_SIZE,
          font,
          color : row.ItemLevel === 'E' ?  rgb(0, 0, 0) :  rgb(1, 1, 1)

        });


        // Draw a line below each data row
        page.drawLine({
          start: { x: PAGE_MARGIN, y: currentPageY - 5 },
          end: { x: page.getWidth() - PAGE_MARGIN, y: currentPageY - 5 },
          //color:[0, 0, 0], // Set the color to black
        });
        currentPageY -= ROW_HEIGHT;
      }


      const pdfBytes = await pdfDoc.save();
      // Create a new blob from the PDF bytes
      const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

      const pdfUrl = URL.createObjectURL(pdfBlob);
   
      const file = new File([pdfBlob], "MC-OrderForm.pdf", {
        type: "application/pdf",
      });
    
      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        try {
          navigator.share({
            title: "Booker Name",
            text: "Madni Corporation (Order Sheet).",
            files: [file],
          });
        } catch (error) {
          console.error("Sharing failed:", error);
        }
      } else {
        alert("File sharing is not supported on this device.");
      }
  

      setLoading(false);
      //naviagte(redirect || '/customer');
    } catch (err) {
      toast.error(getError(err));
    }
  };


  return (
    <Container className="small-container">
      <Helmet>
        <title>Order Sheet </title>
      </Helmet>
      <h1 className="my-3">Cell # </h1>
      <Form onSubmit={submitHandler}>
        <Form.Group className="mb-3" controlId="Cell">
          <Form.Label style={{fontWeight:"bold", fontSize:"16px"}}> +92 3## ### #### (Enter cell number if new, otherwise use Share PDF)</Form.Label>
          <Form.Control
            type="text"
            required
            maxLength="13"
            onChange={(e) => {
              setCode(e.target.value);
            }
          }
          ></Form.Control>
        </Form.Group>
        <div className="mb-3">
          <Button type="submit" >Send Message </Button>
          <h6>(Send message first if it's a new number, then use the Share PDF button)</h6>
        </div>
        <div className="mb-3">
          <Button  onClick={SharePDF} >Share PDF </Button>
          <h6>(Use directly if the cell number is saved)</h6>
        </div>

      </Form>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div></div>
      )}
    </Container>
  );
}
